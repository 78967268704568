import queryString from "query-string";
import React, { useEffect, useState, PureComponent, useLayoutEffect } from 'react'
import { useLocation } from "react-router-dom";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import { TextField, Grid } from '@material-ui/core';
import { errorToast, successToast } from "../shared/notification";
import { Button } from "@mui/material";
import axiosInstance from "../shared/api"
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Link } from "react-router-dom";

const moment = require("moment-timezone");

function RetrieveChartAmchart5() {
	const { search } = useLocation();
	const values = queryString.parse(search);
    const [loading, setLoading] = useState(false)
    const [deviceInfo, setDeviceInfo] = useState({})
    const [calculatedLabel, setCalculatedLabel] = useState([])

	const [buttonIsActive, setButtonIsActive] = useState(true)
	const [startDate, setStartDate] = useState("")
	const [endDate, setEndDate] = useState("")

	function datetimeHandleChange(event) {
        if (type === "chart") {
            if (isMoreThanOneMonth(new Date( document.getElementById("start-date").value + " " + document.getElementById("start-time").value ), new Date( document.getElementById("end-date").value + " " + document.getElementById("end-time").value ))) {
                errorToast("invalid date: date set in more than 1 month")
                setButtonIsActive(false)
            } else {
                setButtonIsActive(true)
            }
        } else if (type === "download") {
            if (isMoreThanSixMonth(new Date( document.getElementById("start-date").value + " " + document.getElementById("start-time").value ), new Date( document.getElementById("end-date").value + " " + document.getElementById("end-time").value ))) {
                errorToast("invalid date: date set in more than 6 month")
                setButtonIsActive(false)
            } else {
                setButtonIsActive(true)
            }
        }
	}

    const isMoreThanOneMonth = (startDate, endDate) => {
        const start = new Date(startDate)
        const end = new Date(endDate)

        // Check if end date is smaller than start date
        if (end < start) {
            return true
        }

        const monthsDiff = end.getMonth() - start.getMonth() + (12 * (end.getFullYear() - start.getFullYear()))
        return monthsDiff > 1 || (monthsDiff === 1 && end.getDate() >= start.getDate())
    }
    const isMoreThanSixMonth = (startDate, endDate) => {
        const start = new Date(startDate)
        const end = new Date(endDate)
        if (end < start) {
            return true
        }
        const monthsDiff = end.getMonth() - start.getMonth() + (12 * (end.getFullYear() - start.getFullYear()))
        return monthsDiff > 6 || (monthsDiff === 6 && end.getDate() >= start.getDate())
    }

	let default_start_time = String( moment(new Date(new Date().setHours(new Date().getHours() - 10))) .tz("Asia/Kuala_Lumpur") .format("HH:mm") );
	let default_end_time = String( moment().tz("Asia/Kuala_Lumpur").format("HH:mm") )
    
    function setDefaultDate() {
		// Set Default Date
		document.getElementById("start-time").value = default_start_time;
		document.getElementById("end-time").value = default_end_time;
		document.getElementById("end-date").valueAsDate = new Date();
		document.getElementById("start-date").valueAsDate = new Date(new Date().setHours(new Date().getHours() - 10))
        
        setStartDate(new Date(new Date().setHours(new Date().getHours() - 10))) // follow same as epochStartTime
        setEndDate(new Date()) // follow same as epochEndTime

        setButtonIsActive(true)
    }

	useEffect(() => {
        setDefaultDate()
        getParameter()
	}, [])

    const [parameterList, setParameterList] = useState([])
    const [jobId, setJobId] = useState(null)
	const [fullChartData2, setFullChartData2] = useState([])

    function getParameter() {
        axiosInstance.get(`https://api.cloudatik.com/chart/v1.0/historical/parameter/${values.device_id}`, { headers : {
            "Authorization": localStorage.getItem('cloudatik-access'),
            "Content-Type": "application/json"
        }}).then(res =>{
            console.log(123, res)
            setParameterList(res.data.data)
            setDeviceInfo(res.data)
        }).catch(err => {
            console.log(234, err)
        })
    }

    function handleParameterClick(index) {
        const updatedList = parameterList.map((item, i) => {
            if (i === index) {
                return { ...item, click: !item.click }
            }
            return item
        })
        setParameterList(updatedList)
    }

    function sendPayload() {
        let new_start_epochtime = moment( new Date( document.getElementById("start-date").value + " " + document.getElementById("start-time").value )).tz("Asia/Kuala_Lumpur").unix();
        let new_end_epochtime = moment( new Date( document.getElementById("end-date").value + " " + document.getElementById("end-time").value )).tz("Asia/Kuala_Lumpur").unix()

        let selectedParameter = []
        for (let param of parameterList){
            if (param.click) selectedParameter.push(param.parameter)
        }
        if (selectedParameter.length < 1) return errorToast("please select parameter")

        // {"device_id": "ylCITw", "start": "1717664304", "end": "1718194310", "parameters": ["t","h","vpd"]}
        let payload = {
            "device_id": values.device_id,
            "start": new_start_epochtime,
            "end": new_end_epochtime,
            "parameters": selectedParameter
        }
        console.log("sendPayload", payload)

        setLoading(true)
        axiosInstance.post(`https://api.cloudatik.com/chart/v1.0/historical/load/`, payload, { headers : {
            "Authorization": localStorage.getItem('cloudatik-access'),
            "Content-Type": "application/json"
        }}).then(res =>{
            // console.log("parameter", res)
            if (res.data.status === false) {
                setLoading(false)
                return alert(res.data.message)
            }
            setJobId(res.data.jobid)
        }).catch(err => {
            setLoading(false)
            console.log(234, err)
        })
    }

    useEffect(() => {
        if (jobId) {
            let count = 0
            const fetchData = async () => {
                const response = await axiosInstance.get(`https://api.cloudatik.com/chart/v1.0/historical/status/${jobId}/`, {
                    headers: { 
                        "Authorization": localStorage.getItem('cloudatik-access'),
                        "Content-Type": "application/json"
                    },
                })
                console.log(234, response)

                if (!response.data.return && count >= 20) {
                    setLoading(false)
                    clearAllInterval()
                    return errorToast("Server error")
                } else if (response.data.return === true && response.data.message == "Historical Chart Data Arrived!") {
                    setJobId(null)
                    setLoading(false)
                    clearAllInterval()
                    console.log("Historical Chart Data Arrived!", response.data.data.chart)
                    setFullChartData2(response.data.data.chart)
                    setLoading(false)
                }
            }
        
            // Initial call
            fetchData()
            // Set up interval for subsequent calls every 3 seconds
            const intervalId = setInterval(() => {
                count += 3
                fetchData()
            }, 3000)
            // Cleanup function
            return () => {
                clearInterval(intervalId)
            }
        }
    }, [jobId])

    // useEffect(() => {
    //     // if (fullChartData2) {
    //     //     setLoading(false)
    //         // createChart2()
    //     // }
    // }, [fullChartData2])
    useLayoutEffect(() => {
        console.log("fullChartData2", fullChartData2)
        let root = am5.Root.new("chartdiv")
        
        for (let series of fullChartData2) {
            for (let data of series.data) {
                data["datetime"] = new Date(data["datetime"])
                data["value"] = parseFloat(data["value"])
            }
        }
        // console.log("series", fullChartData2)
    
        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([ am5themes_Animated.new(root) ])
        
        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                focusable: true,
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX: true,
                layout: root.verticalLayout,
            })
        );
        
        var easing = am5.ease.linear;
        chart.get("colors").set("step", 3);
        
        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                baseInterval: {
                    timeUnit: "minute",
                    count: 1 
                },
                renderer: am5xy.AxisRendererX.new(root, {
                    minGridDistance: 50,
                }),
                tooltip: am5.Tooltip.new(root, {}),
                tooltipDateFormat: "MMM d, yyyy HH:mm",
            })
        );
        
        function createAxisAndSeries(data, name) {
            var yRenderer = am5xy.AxisRendererY.new(root, {});
            var yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    maxDeviation: 1,
                    renderer: yRenderer
                })
            );
        
            if (chart.yAxes.indexOf(yAxis) > 0) {
                yAxis.set("syncWithAxis", chart.yAxes.getIndex(0));
            }
        
            // Add series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
            var series = chart.series.push(
                am5xy.LineSeries.new(root, {
                    name: name,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "value",
                    valueXField: "datetime",
                    tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: "horizontal",
                        labelText: "[bold]{name}[bold]:{valueY}"
                    }),
                    snapTooltip: true
                })
            )

        
            //series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
            series.strokes.template.setAll({ strokeWidth: 1.5 })
        
            yRenderer.grid.template.set("strokeOpacity", 0.05);
            yRenderer.labels.template.set("fill", series.get("fill"));
            yRenderer.setAll({
                stroke: series.get("fill"),
                strokeOpacity: 1,
                opacity: 1
            });
        
            // Set up data processor to parse string dates
            // https://www.amcharts.com/docs/v5/concepts/data/#Pre_processing_data
            series.data.processor = am5.DataProcessor.new(root, {
                dateFormat: "yyyy-MM-dd",
                dateFields: ["datetime"]
            })

            series.data.setAll(data)
        }
        
        for (let data of fullChartData2) {
            createAxisAndSeries(data.data, data.ylabel)
        }


        let legend = chart.children.push(am5.Legend.new(root, {
            x: am5.percent(50),
            centerX: am5.percent(50),
            layout: root.horizontalLayout,
            marginTop: 20
        }));
        legend.data.setAll(chart.series.values);
        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis,
            behavior: "none"
        }));
        cursor.lineY.set("visible", false);
        

        // add scrollbar
        chart.set("scrollbarX", am5.Scrollbar.new(root, {
            orientation: "horizontal"
        }));

        chart.appear(1000, 100)


        return () => {
            root.dispose()
        }
    }, [fullChartData2])



    function clearAllInterval() {
        // Get a reference to the last interval + 1
        const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER)

        // Clear any timeout/interval up to that id
        for (let i = 1; i < interval_id; i++) {
            window.clearInterval(i)
        }
    }

    const [type, setType] = useState(values.page) // chart | download
    const [email, setEmail] = useState("")

    function sendEmail() {
        console.log("sendEmail")
        let new_start_epochtime = moment( new Date( document.getElementById("start-date").value + " " + document.getElementById("start-time").value )).tz("Asia/Kuala_Lumpur").unix();
        let new_end_epochtime = moment( new Date( document.getElementById("end-date").value + " " + document.getElementById("end-time").value )).tz("Asia/Kuala_Lumpur").unix()

        let selectedParameter = []
        for (let param of parameterList){
            if (param.click) selectedParameter.push(param.parameter)
        }
        if (selectedParameter.length < 1) return errorToast("please select parameter")

        // {"device_id": "ylCITw", "start": "1717664304", "end": "1718194310", "parameters": ["t","h","vpd"]}
        let payload = {
            "device_id": values.device_id,
            "start": new_start_epochtime,
            "end": new_end_epochtime,
            "parameters": selectedParameter,
            "email": email
        }

        setLoading(true)
        axiosInstance.post(`https://api.cloudatik.com/chart/v1.0/download/data/`, payload, { headers : {
            "Authorization": localStorage.getItem('cloudatik-access'),
            "Content-Type": "application/json"
        }}).then(res =>{
            console.log(123, res)
            successToast(res.data.msg)
            setLoading(false)
        }).catch(err => {
            console.log(234, err)
            setLoading(false)
        })
    }

	return (
		<div>
        {loading && <LoadingSpinner />}
            <Grid container  style={{ textAlign: 'center', maxWidth: '750px', margin: 'auto', justifyContent:"space-evenly", marginBottom: "10px" }}>
                <Button variant="contained" color="primary" onClick={()=>{
                    setType("chart")
                    setDefaultDate()
                }}>Plot Chart</Button>
                <Button variant="contained" color="secondary" onClick={()=>{
                    setDefaultDate()
                    setType("download")
                    setEmail("")
                }}>Download Raw Data</Button>
                <Link to={`/retrieve-summary?device_id=${values.device_id}`}>
                    <Button variant="contained" color="info">Summary</Button>
                </Link>
            </Grid>
            
			<center>
                <div style={{display: 'flex',  justifyContent: 'center'}}>
                    <h3>{deviceInfo.serial}</h3>
                </div>
				<div style={{ maxWidth: 1200 }}>
					<Row style={{ margin: '0' }}>
						<Col>
							<FormGroup>
								<Label>Start Date</Label>
								<Input
									type="date"
									id="start-date"
									onChange={(e) => {
										datetimeHandleChange(e);
									}}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>Start Time</Label>
								<Input
									type="time"
									id="start-time"
									onChange={(e) => {
										datetimeHandleChange(e);
									}}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row style={{ margin: '0' }}>
						<Col>
							<FormGroup>
								<Label>End Date</Label>
								<Input
									type="date"
									id="end-date"
									onChange={(e) => {
										datetimeHandleChange(e);
									}}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>End Time</Label>
								<Input
									type="time"
									id="end-time"
									onChange={(e) => {
										datetimeHandleChange(e);
									}}
								/>
							</FormGroup>
						</Col>
					</Row>

                    {type === "download" && (
                        <Grid style={{marginBottom: "10px"}}>
                            <TextField
                                value={email}
                                label={"Email"}
                                onChange={(e)=>{setEmail(e.target.value)}}
                            />
                        </Grid>
                    )}

					<div className="row pr-0 py-2 pl-2 mx-0">
                        {parameterList.map( (param, index) => {
                            return(
                                <div className="col-4 col-sm-2" key={index} style={{padding:"15px 5px"}}>
                                    <div className="form-check row" style={{marginRight: 8}}>
                                        <input className="form-check-input col-2" type="checkbox" value={param.unit || ""} onClick={()=>handleParameterClick(index)}/>
                                        <label className="form-check-label col-10">{param.description}</label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
					<br />
                    <div className="row" style={{marginTop: "0.3rem", marginBottom:"1.4rem"}}>
                        {calculatedLabel.map(label => {
                            return (
                                <>
                                <span style={{fontSize: "1.5rem", width: "100%", marginBottom:"0.8rem"}}><b>{label.label} : {label.value} {label.unit}</b></span>    
                                </>
                            )
                        })}
                    </div>

                    {type === "chart" && (
                        <Button variant="contained" color="primary" disabled={!buttonIsActive ? true : false} onClick={() => sendPayload()}>
                            Generate
                        </Button>
                    )}
                    {type === "download" && (
                        <Button variant="outlined" color="primary" disabled={email === "" ? true : (!buttonIsActive ? true : false)} onClick={() => sendEmail()}>
                            Submit Request
                        </Button>
                    )}
                    
            <div id="chartdiv" style={{ width: "98%", height: "500px" }}></div>
				</div>
			</center>
		</div>
	);
}

export default RetrieveChartAmchart5
