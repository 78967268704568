import React, { useEffect, useState, PureComponent, useLayoutEffect } from 'react'
import LoadingSpinner from '../../shared/Spinner/LoadingSpinner'
import { Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { TextField, Grid } from '@material-ui/core';
import queryString from "query-string";

const Summary = () => {
    const [loading, setLoading] = useState(false)
	const { search } = useLocation()
	const values = queryString.parse(search)
    
    return (
		<div style={{paddingLeft: "2rem", paddingRight: "2rem"}}>
        {loading && <LoadingSpinner />}
            <Grid container  style={{ textAlign: 'center', maxWidth: '750px', margin: 'auto', justifyContent:"space-evenly", marginBottom: "10px" }}>
                <Link to={`/retrieve-chart?device_id=${values.device_id}&page=chart`}>
                    <Button variant="contained" color="primary">Plot Chart</Button>
                </Link>
                <Link to={`/retrieve-chart?device_id=${values.device_id}&page=download`}>
                    <Button variant="contained" color="secondary">Download Raw Data</Button>
                </Link>
                <Link to={`/retrieve-summary?device_id=${values.device_id}&page=summary`}>
                    <Button variant="contained" color="info">Summary</Button>
                </Link>
            </Grid>

            <center>
                <div style={{overflowX:"auto"}}>
                    <table className="table" style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>2024-01</th>
                                <th>2024-02</th>
                                <th>2024-03</th>
                                <th>2024-04</th>
                                <th>2024-05</th>
                                <th>2024-06</th>
                                <th>2024-07</th>
                                <th>2024-08</th>
                                <th>2024-09</th>
                                <th>2024-10</th>
                                <th>2024-11</th>
                                <th>2024-12</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </center>
            
		</div>
    )
}

export default Summary